#error-page {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#error-page #tsparticles {
    height: 100vh !important;
}

#error-page span {
    font-size: 50vh;
    font-weight: bold;
}

#error-page .oops {
    font-weight: bold;
    font-size: 2em;
}