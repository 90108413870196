.card {
    color: white;
    min-height: 300px;
    min-width: 200px;
    width: auto;
    background-color: #161128;
    border: 1px solid #53515967;
    transition: all .2s;
    overflow: hidden;
    padding: 15px;
    transform-style: preserve-3d;
}

.card_container.hide {
    opacity: 0;
    transform: translateY(10px);
}

.card_container.show {
    opacity: 1;
    transform: translateY(-10px);
}