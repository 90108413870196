.not_bold {
    font-weight: normal !important;
}

/* === BUTTON === */
.button {
    align-items: center;
    background-clip: padding-box;
    background-color: #D996FF;
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #040013;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
  }
  
  .button:hover,
  .button:focus {
    background-color: #E1ABFF;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: #040013;
  }
  
  .button:hover {
    transform: translateY(-1px);
  }
  
  .button:active {
    background-color: #d087fb;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
  }
  
  
  /* === BUTTON WITH ARROW === */
  
  .button.arrow {
    transition: all .2s;
    padding: calc(.875rem - 1px) 1.5rem;
    display: inline-block;
  }
  
  .button.arrow::after {
    content: '➞';
    position: absolute;
    display: inline-block;
    margin-right: 10px;
    opacity: 0;
    transition: all .2s cubic-bezier(.12,.83,.19,.95);
  
  }
  
  .button.arrow:hover {
    padding-right: calc(1.5rem + 15px);
    transform: translateY(0px);
  }
  
  
  .button.arrow:hover::after {
    transform: translateX(4px);
    margin-right: 15px;
    opacity: 1;
  }
  
  /* === BUTTON SECONDARY COLOR === */
  .button.secondary {
    background-color: rgba(255,255,255,0.06);
    border: 1px solid rgba(255,255,255,0.1);
    color: white;
    overflow: hidden;
    backdrop-filter: blur(7px);

  }

  .button.secondary::after {
    content: "";
    position: absolute;
    top: calc(var(--y, 0) * 1px - 150px);
    left: calc(var(--x, 0) * 1px - 150px);
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(180,171,207,1) 0%, rgba(180,171,207,0) 80%);
    
    opacity: 0;
    transition: opacity 0.2s;
  }

  .button.secondary:hover::after {
    opacity: 0.1;
  }

  .button.secondary:active::after {
    opacity: 0.2;
  }