@font-face {
  font-family: "Montserrat_custom";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat_alternates.woff2") format("woff2");
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #878787 #474747;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}
*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(135, 135, 135, 0.5);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}
::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #4f7bff69;
}

::selection {
  color: white;
  background: #4f7bff69;
}

html {
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -font-smoothing: antialiased;
}

body {
  margin: 0;
  color: white;
  font-family: -apple-system, "Ubuntu", sans-serif;
  font-weight: 400;
  background-color: #040013;
}

b {
  font-weight: bold;
}

/* === LINKS === */
a {
  color: #b2beda;
  transition: all 0.2s;
  text-decoration: none;
}

a:hover {
  color: white;
}

a:active {
  color: #d3dcf1;
}
/* ====== */

h1,
h2,
h3,
h4 {
  font-family: -apple-system, "Montserrat_custom", sans-serif;
  font-size: 70px;
  font-weight: 400;
}

h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  color: #f1eff9;
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 26px;
}

h6 {
  font-size: 20px;
}
p {
  font-size: 16px;
}

b {
  color: #f1eff9;
}

.title {
  font-size: 2em;
}

.leading {
  font-weight: lighter;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.darker {
  color: #d9d9d9;
}

.white {
  color: white;
}

.fade_in {
  opacity: 0;
  animation-name: fade_in;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes fade_in {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
