.postersContainer {
  background-color: #140d31;
  height: 100vh;
  width: 100vw;
  padding: 10vw 100px;
}

.posterCard {
  cursor: pointer;
  padding: 0;
  width: fit-content;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.posterCard img {
  border-radius: 5px;
}

.posterCardContent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 500;
  padding-bottom: 15px;
  padding-top: 15px;
  background: rgb(2, 0, 33);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 33, 0.7512254901960784) 0%,
    rgba(2, 0, 33, 0.46551120448179273) 58%,
    rgba(255, 255, 255, 0) 93%
  );
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.posterCardOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.posterCard:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.posterCard:hover .posterCardOverlay {
  background-color: rgba(0, 0, 0, 0.1);
}

.posterCard:hover .posterCardContent {
  opacity: 1;
}
