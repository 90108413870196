.navbar {
    width: 100vw;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 10%;
    display: flex;
    justify-content: center;
    transition: all .2s;
}

.navbar > div > * {
    padding-left: 15px;
    padding-right: 15px;
}

.navbar > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar img {
    padding: 0;
}

.navbar div:first-child {
    margin-right: auto;
}

.navbar div:last-child {
    margin-left: auto;
}

.navbar.solid {
    background-color: #040013;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;}