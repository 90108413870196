.App {
  overflow-x: hidden;
  padding: 30px 15px;
}

#tsparticles {
  position: absolute;
  height: 75vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  z-index: -1;
}

#header {
  height: 75vh;
  margin-left: 5vw;
}

#header .row > * {
  padding: 0;
}

#image-logo {
  position: absolute;
  width: 100vw;
  height: 100vh !important;
  left: 0;
  top: 0;
  pointer-events: none;
  overflow: hidden;
}

#wrapper-logo {
  position: absolute;
  pointer-events: none;
  left: calc(50% - 100px);
  top: 30%;
  transition: all 1s ease-in-out;
}

#wrapper-logo svg {
  transform: none !important;
}

#header-content {
  height: 100%;
}

.hidden {
  opacity: 0;
}

#body .background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(16, 10, 41, 1) 15%,
    rgba(16, 10, 41, 1) 50%,
    rgba(16, 10, 41, 1) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-left: calc(-50vw + 50%);
}

#body {
  position: relative;
}

.body-text {
  padding: 0;
}

.body-text span {
  transition: opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

.body-text .text-icon {
  transition: opacity 0.7s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.7s cubic-bezier(0.16, 1, 0.3, 1);
}

.body-text .text-title {
  transition-delay: 0.2s;
}

.body-text .text-detail {
  transition-delay: 0.3s;
}

.body-text.hide span {
  opacity: 0;
  transform: translateX(15px);
}

.body-text.show span {
  opacity: 1;
  transform: translateX(0px);
}

.body-text.hide .text-icon {
  opacity: 0;
  transform: scale(0.96);
}

.body-text.show .text-icon {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  .body-text {
    padding-left: 50px;
  }

  .App {
    padding: 30px 15vw;
  }

  #header {
    margin-left: 0;
  }
}
